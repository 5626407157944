@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f7f7f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3e4e5c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3b3f53;
}

.small-bar::-webkit-scrollbar {
  width: 4px;
}

.small-bar::-webkit-scrollbar-track {
  background: #f3fcff;
}

.small-bar::-webkit-scrollbar-thumb {
  background: #3e4e5c;
}

.small-bar::-webkit-scrollbar-thumb:hover {
  background: #3b3f53;
}

.react-datetime-picker__wrapper {
  display: flex !important;
  border: 1px solid #979aa480 !important;
  padding: 6px !important;
  border-radius: 5px !important;
  position: relative !important;
  width: 100% !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}

.react-datetime-picker__calendar .react-calendar {
  border: 1px solid #1b1b1b3a !important;
  border-radius: 5px !important;
  position: absolute !important;
  top: 5px !important;
  width: 100% !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 100;
}

.date-range-container {
  position: relative;
}

.date-range-btn {
  cursor: pointer;
  @apply bg-white px-6 py-1 border text-[#0E2D9B] rounded-md;
}

.date-range-btn button {
  font-size: 0.75rem;
  font-weight: 600;
  border: none;
  position: relative;
}

.date-range-btn button span {
  position: absolute;
  top: 0px;
  left: 3px;
  font-size: 0.45rem;
  font-weight: 700;
}

.date-range-picker {
  position: absolute;
  top: 0px;
  right: 0px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
  z-index: 100;
  top: 100%;
}

.date-range-picker.startDate {
  right: 50%;
}
.date-range-picker.endDate {
  right: 0%;
}

.custom-modal ::-webkit-scrollbar-track {
  background-color: transparent; /* Color of the scrollbar track */
}

.custom-modal ::-webkit-scrollbar-thumb {
  background-color: #bebebe; /* Color of the scrollbar thumb */
  border-radius: 12px; /* Rounded corners for the thumb */
}

.shadowV1 {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}
